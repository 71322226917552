import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Card, Typography, Input } from 'antd';
import {
  PhoneOutlined,
  MailOutlined,
  PercentageOutlined,
  FieldNumberOutlined,
  DollarOutlined,
  UserOutlined,
  CompassOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { success, request } from '../../tools';
import { setCurrentAuthUserPersonalDate } from '../../store/commonReducer';

import './CurrentUserPersonalInfo.scss';
import ScheduleItem from './ScheduleItem';
import { CURRENCIES } from 'common_constants/business';
import { translation } from 'common_constants/translation';

const { Text } = Typography;

const CurrentUserPersonalInfo = () => {
  const dispatch = useDispatch();

  const currentAuthUserPersonalDate = useSelector((state) => state.common.currentAuthUserPersonalDate);
  const lang = useSelector((state) => state.common.language);
  const [schedule, setSchedule] = useState(currentAuthUserPersonalDate?.schedule || []);
  const [stripeId, setStripeId] = useState(currentAuthUserPersonalDate?.stripeId || '');

  const onSave = () => {
    request.post('/profile/updatePartnerPersonalData', { schedule }, () => {
      onSuccess('schedule', schedule);
    });
  };

  const addStripeId = () => {
    request.post('/profile/addStripeId', { stripeId }, () => {
      onSuccess('stripeId', stripeId);
    });
  };

  const onSuccess = (key, value) => {
    success();
    const updatedData = { ...currentAuthUserPersonalDate, [key]: value };
    dispatch(setCurrentAuthUserPersonalDate(updatedData));
  };

  return (
    <div className="currentUserPersonalInfo">
      <Row className="currentUserPersonalInfo__row" gutter={[16, 20]}>
        {/* Name & Contact Section */}
        <Col xs={24} md={24} lg={24}>
          <Card title={translation.contactData[lang]} bordered={false}>
            <UserOutlined style={{ marginRight: 8 }} />
            <Text strong>{translation.fullName[lang]}:</Text> {currentAuthUserPersonalDate.name || '-'}
            <br />
            <PhoneOutlined style={{ marginRight: 8 }} />
            <Text strong>{translation.phone[lang]}:</Text> {'+' + currentAuthUserPersonalDate.countryPhCode}{' '}
            {currentAuthUserPersonalDate.phone || '-'}
            <br />
            <MailOutlined style={{ marginRight: 8 }} />
            <Text strong>{translation.partnersApp.email[lang]}</Text> {currentAuthUserPersonalDate.email || '-'}
            <br />
            <FieldNumberOutlined style={{ marginRight: 8 }} />
            <Text strong>{translation.certificate[lang]}:</Text> {currentAuthUserPersonalDate.certificate || '-'}
            <br />
            <CompassOutlined style={{ marginRight: 8 }} />
            <Text strong>{translation.addressOffice[lang]}:</Text> {currentAuthUserPersonalDate.adress || '-'}
            <br />
            {currentAuthUserPersonalDate.company && (
              <>
                <BankOutlined style={{ marginRight: 8 }} />
                <Text strong>{translation.company[lang]}:</Text> {currentAuthUserPersonalDate.company}
                <br />
              </>
            )}
            {currentAuthUserPersonalDate?.price && (
              <div>
                <DollarOutlined style={{ marginRight: 8 }}></DollarOutlined>
                <Text strong>
                  {translation.partnersApp.price[lang]}{' '}
                  {currentAuthUserPersonalDate?.price + ' ' + CURRENCIES[currentAuthUserPersonalDate?.currency]?.symbol}
                </Text>
              </div>
            )}
            <PercentageOutlined style={{ marginRight: 8 }} />
            <Text strong>{translation.share[lang]}:</Text> {currentAuthUserPersonalDate.share || '-'}%
            <br />
          </Card>
        </Col>
        <Col xs={24} md={24} lg={24}>
          <Card title="Stripe id" bordered={false}>
            <div className="currentUserPersonalInfo__stripe-input-wrapper">
              <Input value={stripeId} onChange={(e) => setStripeId(e.target.value)} style={{ width: '200px' }} />
              <Button type="primary" onClick={addStripeId}>
                {translation.save[lang]}
              </Button>
            </div>
          </Card>
        </Col>
        {/* Working Days & Hours Section */}
        {schedule && (
          <Col xs={24} md={24} lg={24}>
            <Card title={translation.workingSchedule[lang]} bordered={false}>
              {[...schedule]
                .sort((a, b) => a.key - b.key)
                .map((item, index) => {
                  return <ScheduleItem item={item} key={index} schedule={schedule} setSchedule={setSchedule} />;
                })}
            </Card>
          </Col>
        )}
      </Row>

      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 32 }}>
        <Button type="primary" onClick={onSave}>
          {translation.save[lang]}
        </Button>
      </div>
    </div>
  );
};

export default CurrentUserPersonalInfo;
