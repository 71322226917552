import { ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { App, Button, Calendar, Empty, ConfigProvider, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_PARTNERS_HOTLINE } from 'common_constants/modals';
import { setCurrentHotlineList, setModal } from '../../store/commonReducer';
import { error, request, warn } from '../../tools';
import { translation, languageOptions } from 'common_constants/translation';

import dayjs from 'dayjs';

import 'dayjs/locale/uk';
import 'dayjs/locale/cs';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/ru';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';

import uk_UA from 'antd/es/locale/uk_UA';
import cs_CZ from 'antd/es/locale/cs_CZ';
import en_US from 'antd/es/locale/en_US';
import de_DE from 'antd/es/locale/de_DE';
import ru_RU from 'antd/es/locale/ru_RU';
import fr_FR from 'antd/es/locale/fr_FR';
import es_ES from 'antd/es/locale/es_ES';

import Item from './Item';

import './List.scss';

const { Text } = Typography;

const DAYJS_LANGS = {
  [languageOptions[0].value]: uk_UA, // Ukrainian
  [languageOptions[1].value]: cs_CZ, // Czech
  [languageOptions[2].value]: en_US, // English
  [languageOptions[3].value]: de_DE, // German
  [languageOptions[4].value]: ru_RU, // Russian
  [languageOptions[5].value]: fr_FR, // French
  [languageOptions[6].value]: es_ES, // Spanish
};

const List = () => {
  const { modal } = App.useApp();
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.common.language);
  const list = useSelector((state) => state.common.currentHotlineList);

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs());

  const locale = DAYJS_LANGS[lang];
  dayjs.locale(lang);

  const updateRecordInList = (updatedRecord) => {
    const updatedList = list.map((item) => (item.phone === updatedRecord.phone ? updatedRecord : item));

    dispatch(setCurrentHotlineList(updatedList));
  };
  const getList = async (date) => {
    const validation = (date) => {
      if (!date) {
        warn('', transaction.chooseDateForShowHotlines[lang], { date: date });
        return false;
      }
      return true;
    };

    if (!validation(date)) return;

    const dateString = date?.toISOString();

    setLoading(true);

    const transaction = {
      date: dateString,
    };

    await request.post(
      '/partnersHotlines/getAllHotlines',
      transaction,
      ({ hotlines }) => {
        dispatch(setCurrentHotlineList(hotlines));
      },
      (error) => {
        console.error('Error in request:', error);
      },
    );

    setLoading(false);
  };

  const removeConfirm = (data) => {
    modal.confirm({
      title: translation.removeHotlineAlert[lang],
      icon: <ExclamationCircleOutlined />,
      okText: translation.yes[lang],
      okType: 'danger',
      cancelText: translation.no[lang],
      onOk() {
        console.log('');
        onRemoveClick(data);
      },
      onCancel() {
        console.log('');
      },
    });
  };

  const onAddSuccess = () => {
    getList(date);
  };

  const onEditClick = (data) => {
    dispatch(setModal({ name: ADD_PARTNERS_HOTLINE, data, getList: getList, dateForList: date }));
  };

  const onRemoveClick = (data) => {
    const validation = (data) => {
      if (!data._id) {
        warn('', translation.chooseDateForShowHotlines[lang], { 'data.id': data.id });
        return;
      }
      return true;
    };

    if (!validation(data)) return;

    setLoading(true);

    const transaction = {
      _id: data._id,
    };

    request.post(
      '/partnersHotlines/remove',
      transaction,
      (req) => {
        req.status && getList(date);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getList(date);
  }, []);

  return (
    <Spin tip={translation.loader1[lang]} spinning={loading}>
      <div className="list-page default-page">
        <h2>{translation.hotlines[lang]}</h2>
        <Row style={{ justifyContent: 'center' }}>
          <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_PARTNERS_HOTLINE, data: { onAddSuccess } }))}>
            <PlusCircleOutlined /> {translation.addHotline[lang]}
          </Button>
        </Row>

        <br />

        <ConfigProvider locale={locale}>
          <Calendar
            value={date}
            onSelect={(dateValue) => {
              setDate(dayjs(dateValue));
              getList(dayjs(dateValue));
            }}
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
              maxWidth: '580px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            fullscreen={false}
          />
        </ConfigProvider>

        <br />

        {!list?.length ? (
          <Empty description={<Text>{translation.noHotlines[lang]}</Text>} />
        ) : (
          list.map((i, index) => (
            <Item
              i={i}
              key={i._id}
              index={index}
              onEditClick={onEditClick}
              removeConfirm={removeConfirm}
              updateRecord={updateRecordInList}
              getList={getList}
              date={date}
            />
          ))
        )}
      </div>
    </Spin>
  );
};

export default List;
