import React from 'react';
import { Select } from 'antd';
import { POSLUGI_FOR_PARTNERS } from 'common_constants/business/index';
import { translation } from 'common_constants/translation';

const SearchSelectType = ({ type, onChangeType, lang }) => (
  <Select
    showSearch
    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    className="type"
    placeholder={translation.clientHotline.selectHotlineType[lang]}
    defaultValue={type}
    onChange={onChangeType}
  >
    {POSLUGI_FOR_PARTNERS.map((service) => (
      <Select.Option key={service.index} value={String(service.index)}>
        {service.value[lang]}
      </Select.Option>
    ))}
  </Select>
);

export default SearchSelectType;
