import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Modal } from 'antd';
import { translation } from 'common_constants/translation';

import { error, request } from '../../tools';
import { setModal } from '../../store/commonReducer';
import Item from '../../pages/List/Item';
import Box from '../../components/Box/Box';

const HistoryHotlines = () => {
  const dispatch = useDispatch();
  const { phone, countryPhCode } = useSelector((state) => state.common.modal.data);
  const lang = useSelector((state) => state.common.language);

  const [hotlines, setHotlines] = useState([]);
  const [loading, setLoading] = useState(false);

  const onCancel = () => dispatch(setModal());

  const getHotlineResult = () => {
    request.post(
      '/partnersHotlines/getByPhone',
      { phone: phone, countryPhCode: countryPhCode },
      (res) => {
        const sorted =
          res.data?.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          }) || [];
        setHotlines(sorted);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    setLoading(true);
    getHotlineResult();
  }, []);

  return (
    <Spin tip={translation?.loader[lang]} spinning={loading}>
      <Modal className="library-info-list" open title={translation.historyConsultations[lang]} onCancel={onCancel} footer={null}>
        <Box>
          {hotlines.map((item) => (
            <Item i={item} key={item._id} />
          ))}
        </Box>
      </Modal>
    </Spin>
  );
};

export default HistoryHotlines;
