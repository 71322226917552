import { useSelector } from 'react-redux';
import { WORK_DAYS, WORK_TIME } from 'common_constants/business';
import { Button, Collapse } from 'antd';
import { translation } from 'common_constants/translation';

const { Panel } = Collapse;

const ScheduleItem = ({ item, setSchedule, schedule }) => {
  const lang = useSelector((state) => state.common.language);

  const updateSchedule = (slots) => {
    const updatedSchedule = schedule.map((i) => {
      if (i.key === item.key) {
        return { key: i.key, slots };
      }
      return i;
    });
    setSchedule(updatedSchedule);
  };

  const handleButtonClick = (time) => {
    const slots = item?.slots?.includes(time) ? item.slots.filter((el) => el !== time) : [...(item?.slots || []), time];

    const sortedSlots = slots.sort((a, b) => {
      const [ah, am] = a.split(':').map(Number);
      const [bh, bm] = b.split(':').map(Number);
      return ah - bh || am - bm;
    });

    updateSchedule(sortedSlots);
  };

  const currentDay = WORK_DAYS.find((i) => i.key === item.key);

  return (
    <Collapse bordered={false} style={{ marginBottom: 16 }}>
      <Panel
        header={
          <span style={{ marginRight: '8px' }}>
            {currentDay.label[lang]}
            {(!item.slots || item.slots?.length === 0) && `: ${translation.weekend[lang]}`}
          </span>
        }
        key="1"
      >
        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', flexWrap: 'wrap', marginLeft: 'auto', marginRight: 'auto' }}>
          {WORK_TIME.map((time) => {
            const isSelected = item.slots ? item.slots.includes(time) : false;

            return (
              <Button
                key={time}
                type={isSelected ? 'primary' : 'default'}
                style={{
                  backgroundColor: isSelected ? '#1677ff' : '',
                  color: isSelected ? 'white' : '',
                  width: '80px',
                }}
                onClick={() => handleButtonClick(time)}
              >
                {time}
              </Button>
            );
          })}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
          <Button type="default" onClick={() => updateSchedule([])} style={{ backgroundColor: '#ff2e2e', color: 'white' }}>
            {translation.clear[lang]}
          </Button>
        </div>
      </Panel>
    </Collapse>
  );
};

export default ScheduleItem;
