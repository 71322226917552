import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Card, Spin, Tag, message } from 'antd';
import { orange, volcano } from '@ant-design/colors';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  DiffOutlined,
  DownOutlined,
  EditFilled,
  UpOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { CURRENCIES, HOTLINE_MEET, POSLUGI_FOR_PARTNERS } from 'common_constants/business';
import { HISTORY_HOTLINES, HOTLINE_MEET_MODAL, NEW_CONTRACT } from 'common_constants/modals';
import { translation } from 'common_constants/translation';

import Box from '../../components/Box/Box';
import useResize from '../../components/useResize';
import { setModal } from '../../store/commonReducer';
import { error, request } from '../../tools';

import './List.scss';

const getSerializbleItemData = (item) => {
  return {
    ...item,
    date: item?.date instanceof Date ? item.date.toISOString() : null,
    crAt: item?.crAt instanceof Date ? item.crAt.toISOString() : null,
  };
};

const Item = ({ i, index, onEditClick, removeConfirm, getList, date }) => {
  const dispatch = useDispatch();
  const resize = useResize();

  const lang = useSelector((state) => state.common.language);
  const userAuth = useSelector((state) => state.common.userAuth),
    users = useSelector((state) => state.common.users),
    user = users?.[userAuth?._id];

  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [historyAmount, setHistoryAmount] = useState(0);
  const [status, setStatus] = useState(i?.paid);

  const formattedCrAt = i.crAt ? dayjs(i.crAt).format('DD.MM.YYYY HH:mm:ss') : '-';
  const statusIcon = { 1: <CheckOutlined />, 2: <CloseOutlined /> }[i.s] || <DiffOutlined />;

  const expandCard = (e) => {
    if (e.target.classList.contains('ant-select-selection-search-input')) return;

    setExpand(true);
  };

  const checkStatus = async (sessionId, hotlineId) => {
    if (!sessionId) {
      return;
    }
    let status = false;
    await request.post('/partnersHotlines/checkStripeStatus', { sessionId, hotlineId }, (res) => {
      if (res.paymentStatus === 'paid') {
        setStatus(true);
        status = true;
      }
    });
    return status;
  };

  const calculateAmount = (price, share) => {
    const partnerAmount = Math.round(price * (share / 100));
    const companyAmount = price - partnerAmount;
    return { partnerAmount, companyAmount };
  };
  const { partnerAmount, companyAmount } = calculateAmount(i?.cost, user?.share);

  const handleCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        message.success(translation.paymentLinkCopied[lang]);
      })
      .catch(() => {
        message.error(translation.paymentLinkCopiedError[lang]);
      });
  };

  useEffect(() => {
    if (!expand) return;
    setLoading(true);

    request.post(
      '/partnersHotlines/getAmountByPhone',
      { phone: i.phone },
      ({ amount }) => {
        setHistoryAmount(amount);
        setLoading(false);
      },
      error,
    );
  }, [expand, i.phone]);

  useEffect(() => {
    const fetchStatuses = async () => {
      await checkStatus(i?.sessionId, i?._id);
    };
    if (!i?.paid) {
      fetchStatuses();
    }
  }, [i]);

  const handleAcceptAndConfirmMeet = async () => {
    setLoading(true);

    await request.post(
      `/partnersHotlines/acceptAndConfirm/${i._id}`,
      null,
      () => {
        setLoading(false);
      },
      error,
    );

    await getList(date);
  };

  if (!expand)
    return (
      <Card
        key={index}
        data-id={i._id}
        className={clsx({ 'card hotline-list-item': true, disable: i.disabled, edited: i.e })}
        style={i.e ? { backgroundColor: orange[2] } : i.disabled ? { backgroundColor: volcano[2] } : {}}
        onClick={expandCard}
      >
        <Spin tip={translation.loader[lang]} spinning={loading}>
          <div className="card-content">
            <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
              <span className="item">
                <b>{translation.fullName[lang]}:</b>
                {i.name ?? '-'}
              </span>
              <div className="btns">
                {!i.disabled && (
                  <Button className={`meet t${i.s}`} type="primary">
                    {statusIcon}
                  </Button>
                )}

                <Button className="expand" onClick={() => setExpand(false)}>
                  <DownOutlined />
                </Button>
              </div>
            </div>
            <div className="row">
              <span className="item">
                <b>{translation.time[lang]}:</b> {i.startT ?? '-'} - {i.endT ?? '-'}
              </span>
            </div>
          </div>
        </Spin>
      </Card>
    );

  return (
    <Card
      key={index}
      className={clsx({
        'card two_columns': true,
        disable: i.disabled,
        edited: i.e,
        expand,
      })}
      style={i.e ? { backgroundColor: orange[2] } : i.disabled ? { backgroundColor: volcano[2] } : {}}
    >
      <Spin size="large" tip={translation.loader[lang]} spinning={loading}>
        <div className="card-header">
          {i.startT ?? '-'} - {i.endT ?? '-'}
          <div className="btns">
            {i.disabled ? (
              <div style={{ color: 'red' }}> {i.e ? translation.edited[lang] + translation.and[lang] : translation.removed[lang]}</div>
            ) : (
              <>
                {i?.author && <Button className="btn edit" onClick={() => onEditClick(i)} icon={<EditFilled />}></Button>}

                <Button className="btn remove" onClick={() => removeConfirm(i)} icon={<DeleteFilled />}></Button>
              </>
            )}

            <Button className="expand" onClick={() => setExpand(false)} icon={<UpOutlined />}></Button>
          </div>
        </div>
        <div className="card-content">
          <div className="container2">
            <div className="row_expand">
              <b>{translation.fullName[lang]}:</b>
              <span>{i.name ?? '-'}</span>
            </div>
            <div className="row_expand">
              <b>{translation.phone[lang]}:</b> +{i?.countryPhCode} {i.phone ?? '-'}
            </div>
          </div>
          <div className="container2">
            <div className="row_expand">
              <b>{translation.typeConsultation[lang]}:</b>
              <span>{POSLUGI_FOR_PARTNERS.find((service) => service.index == i.type)?.value[lang] ?? '-'}</span>
            </div>
            <div className="row_expand">
              <b>{resize[0] >= 500 ? translation.meetingResult[lang] : translation.meetingResultShort[lang]}</b>
              <Tag
                style={{
                  width: 60,
                  height: 20,
                  fontSize: 12,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                color={HOTLINE_MEET[i.s] === HOTLINE_MEET[1] ? 'green' : HOTLINE_MEET[i.s] === HOTLINE_MEET[2] ? 'red' : 'yellow'}
              >
                {i?.s ? translation.meetingResultValues[i.s][lang] : '-'}
              </Tag>
            </div>
          </div>
          <div className="container2">
            <div className="row_expand">
              <b>{translation.createdAt[lang]}</b> {formattedCrAt}
            </div>
            <div className="row_expand"></div>
          </div>
          <div className="container2"></div>

          <div>
            <b style={{ color: '#191919', fontWeight: '600' }}>{translation.comment[lang]}:</b> {i.comment !== '' ? i.comment : '-'}
          </div>

          <div className="payment-block">
            <div>
              <div style={{ width: 180 }}>
                <b>{translation.price[lang]}:</b>{' '}
                <span style={{ color: '#777777' }}>
                  {CURRENCIES[i?.currency]?.symbol}
                  {i.cost ?? '-'}
                </span>
              </div>
              <br />
              <div style={{ marginBottom: '4px' }}>
                <b>{translation.partnerTitle[lang]}:</b>
                <Tag color="blue" style={{ fontWeight: 'bold' }}>
                  {user?.share}%
                </Tag>
                <span style={{ color: '#777777' }}>
                  {CURRENCIES[i?.currency]?.symbol}
                  {partnerAmount}
                </span>
              </div>
              <div style={{ marginBottom: '4px' }}>
                <b>{translation.companyTitle[lang]}:</b>
                <Tag color="blue" style={{ fontWeight: 'bold' }}>
                  {100 - user?.share}%
                </Tag>
                <span style={{ color: '#777777' }}>
                  {CURRENCIES[i?.currency]?.symbol}
                  {companyAmount}
                </span>
              </div>
            </div>
            <div className="icons-wrapper">
              {i?.paid || status ? (
                <div className="payment-cash">
                  <CheckCircleOutlined style={{ fontSize: '24px' }} />
                  <span>{translation.payedTag[lang]}</span>
                </div>
              ) : i?.paymentLink ? (
                <>
                  <button className="payment-link" onClick={() => handleCopy(i.paymentLink)}>
                    <LinkOutlined style={{ fontSize: '24px' }} />
                    {translation.copyLink[lang]}
                  </button>
                  <br />
                  {i?.sessionId && (
                    <button
                      className="payment-link"
                      onClick={async () => {
                        const res = await checkStatus(i?.sessionId, i?._id);
                        if (res) {
                          message.success(translation.payedTag[lang]);
                        } else {
                          message.error(translation.unpayedTag[lang]);
                        }
                      }}
                    >
                      <SyncOutlined style={{ fontSize: '24px' }} />
                      {translation.checkStatus[lang]}
                    </button>
                  )}
                </>
              ) : (
                <div className="payment-error">
                  <ExclamationCircleOutlined style={{ fontSize: '24px' }} />
                  <span>{translation.paymentError[lang]}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card-footer">
          <div className="row full controls">
            <Box mt={12} className="group-button">
              {!i.s &&
                !i.disabled &&
                (i?.author ? (
                  <Button
                    className="btn-list"
                    type="primary"
                    onClick={() => dispatch(setModal({ name: HOTLINE_MEET_MODAL, data: getSerializbleItemData(i) }))}
                  >
                    {translation.reportMeeting[lang]}
                  </Button>
                ) : (
                  <Button className="btn-list" type="primary" onClick={() => handleAcceptAndConfirmMeet()}>
                    {translation.acceptAndConfirmMeeting[lang]}
                  </Button>
                ))}
              {i.s === '1' && (
                <Button
                  className="btn-list"
                  type="primary"
                  disabled={i.K}
                  onClick={() => dispatch(setModal({ name: NEW_CONTRACT, data: { hotline: i }, hotline: i }))}
                >
                  {i.K && <CheckOutlined />} {i.K ? translation.contractAdded[lang] : translation.addContract[lang]}
                </Button>
              )}
            </Box>

            <Box mt={12} className="group-button">
              <Button
                onClick={() => dispatch(setModal({ name: HISTORY_HOTLINES, data: { phone: i.phone, countryPhCode: i.countryPhCode } }))}
                className="btn-list"
              >
                {translation.historyWithAmount[lang].replace('{historyAmount}', historyAmount)}
              </Button>
            </Box>
          </div>
        </div>
      </Spin>
    </Card>
  );
};

export default Item;
