import { useEffect, useState } from 'react';
import {
  CheckCircleOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Checkbox, Typography, Button, Tag, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { error, request, success } from '../../../tools';
import { setContractsList } from '../../../store/commonReducer';

import './ContractServices.scss';

const ITEMS_PER_PAGE = 6;

const ServiceItem = ({ item, lang, contractId }) => {
  const [status, setStatus] = useState(item?.paid);
  const { Text } = Typography;

  const date = new Date(item?.date).toLocaleString('UK-ua', {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric',
  });

  const calculateAmount = (price, share) => {
    const partnerAmount = Math.round(price * (share / 100));
    const companyAmount = price - partnerAmount;
    return { partnerAmount, companyAmount };
  };

  const { partnerAmount, companyAmount } = calculateAmount(item?.price, item?.share);

  const checkStatus = async (sessionId, serviceId, contractId) => {
    if (!sessionId) {
      return;
    }
    let status = false;
    await request.post('/partnersContracts/checkStripeStatus', { sessionId, serviceId, contractId }, (res) => {
      if (res.paymentStatus === 'paid') {
        setStatus(true);
        status = true;
      }
    });
    return status;
  };

  const handleCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        message.success(translation.paymentLinkCopied[lang]);
      })
      .catch(() => {
        message.error(translation.paymentLinkCopiedError[lang]);
      });
  };

  useEffect(() => {
    const fetchStatuses = async () => {
      await checkStatus(item?.sessionId, item?._id, contractId);
    };
    if (!item?.paid && !item?.cashPayment) {
      fetchStatuses();
    }
  }, [item, contractId]);

  return (
    <>
      <div key={item._id} className="service-card">
        <div>
          <p>
            <Text strong>{translation.date[lang]}:</Text>&nbsp;
            <Text>{date}</Text>
          </p>
          <p>
            <Text strong>{translation.service[lang]}:</Text>&nbsp;
            <Text>{item?.service}</Text>
          </p>
          <p>
            <Text strong>{translation.sum[lang]}:</Text>&nbsp;
            <Text>Kč {item?.price}</Text>
          </p>
          <p className="payment-text" style={{ marginBottom: '8px' }}>
            <Text strong>{translation.partnerTitle[lang]}:</Text>
            <Tag color="blue" style={{ fontWeight: 'bold' }}>
              {item?.share}%
            </Tag>
            <Text>Kč {partnerAmount}</Text>
          </p>
          <p className="payment-text">
            <Text strong>{translation.companyTitle[lang]}:</Text>
            <Tag color="blue" style={{ fontWeight: 'bold' }}>
              {100 - item?.share}%
            </Tag>
            <Text>Kč {companyAmount}</Text>
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          {item?.cashPayment ? (
            <div className="payment-cash">
              <CheckCircleOutlined style={{ fontSize: '24px' }} />
              <span>{translation.paidWythCash[lang]}</span>
            </div>
          ) : item?.paid || status ? (
            <div className="payment-cash">
              <CheckCircleOutlined style={{ fontSize: '24px' }} />
              <span>{translation.payedTag[lang]}</span>
            </div>
          ) : item.url ? (
            <>
              <button className="payment-link" onClick={() => handleCopy(item.url)}>
                <LinkOutlined style={{ fontSize: '24px' }} />
                {translation.copyLink[lang]}
              </button>
              <br />
              {item?.sessionId && (
                <button
                  className="payment-link"
                  onClick={async () => {
                    const res = await checkStatus(item.sessionId, item._id, contractId);
                    if (res) {
                      message.success(translation.payedTag[lang]);
                    } else {
                      message.error(translation.unpayedTag[lang]);
                    }
                  }}
                >
                  <SyncOutlined style={{ fontSize: '18px' }} />
                  {translation.checkStatus[lang]}
                </button>
              )}
            </>
          ) : (
            <div className="payment-error">
              <ExclamationCircleOutlined style={{ fontSize: '24px' }} />
              <span>{translation.paymentError[lang]}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ContractServices = ({ data }) => {
  const dispatch = useDispatch();

  const [service, setService] = useState('');
  const [price, setPrice] = useState('');
  const [cashPayment, setCashPayment] = useState(false);
  const [currentServices, setCurrentServices] = useState(data.services || []);
  const [currentPage, setCurrentPage] = useState(1);

  const contractsList = useSelector((state) => state.common.contracts);
  const lang = useSelector((state) => state.common.language);
  const userAuth = useSelector((state) => state.common.userAuth),
    users = useSelector((state) => state.common.users),
    user = users?.[userAuth?._id];

  const addService = async () => {
    const formattedDate = new Date().toISOString();

    const requestData = {
      amount: Number(price) * 100,
      _id: data._id,
      service: {
        date: formattedDate,
        service,
        cashPayment,
        price: Number(price),
        share: user?.share,
      },
    };

    await request.post(
      '/partnersContracts/addContractServices',
      { ...requestData },
      (res) => {
        const updatedContractsList = contractsList.map((item) => (item._id === res.contract._id ? res.contract : item));

        dispatch(setContractsList(updatedContractsList));
        setCurrentServices(res.contract.services);
        success();
      },
      error,
    );

    setPrice('');
    setService('');
    setCashPayment(false);
  };

  const paginatedServices = currentServices.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const totalPages = Math.ceil(currentServices.length / ITEMS_PER_PAGE);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="partners-services">
      <div className="add-service-card">
        <div className="form-group">
          <input
            value={service}
            className="form-input _title"
            onChange={(e) => setService(e.target.value)}
            placeholder={translation.describeService[lang]}
          />
        </div>

        <div className="form-group">
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            value={price}
            className="form-input _title"
            onChange={(e) => setPrice(e.target.value)}
            placeholder={translation.sum[lang]}
          />
        </div>

        <div className="form-chekbox">
          <span>{translation.paidWythCash[lang]}:</span>
          <Checkbox checked={cashPayment} onChange={() => setCashPayment(!cashPayment)} />
        </div>

        <div className="add-new-task">
          <button className={`${!service || !price ? 'disabled' : 'save-task'}`} disabled={!service || !price} onClick={addService}>
            <PlusOutlined /> <p>{translation.addService[lang]}</p>
          </button>
        </div>
      </div>

      {currentServices.length !== 0 && (
        <>
          <div className="partners-services-grid">
            {paginatedServices.map((item, index) => {
              return <ServiceItem key={index} item={item} lang={lang} contractId={data?._id} />;
            })}
          </div>

          {totalPages > 1 && (
            <div className="pagination-controls">
              <Button onClick={prevPage} disabled={currentPage === 1}>
                <LeftOutlined />
              </Button>
              <span>
                {translation.page[lang]} {currentPage} {translation.of[lang]} {totalPages}
              </span>
              <Button onClick={nextPage} disabled={currentPage === totalPages}>
                <RightOutlined />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ContractServices;
