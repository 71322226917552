import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { NEW_CONTRACT } from 'common_constants/modals';
import { setContractsList, setModal } from '../../store/commonReducer';
import { getColumns } from './ContractsHelpers';
import ContractInfo from './ContractInfo';

import './Contracts.scss';
import { translation } from 'common_constants/translation';
import { request } from '../../tools';

const Contracts = () => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.common.contracts);
  const mob = useSelector((state) => state.ui.mob);
  const lang = useSelector((state) => state.common.language);

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const [contractsFilters, setContractsFilters] = useState({
    page: 1,
  });

  const handleTableChange = (pagination) => {
    setContractsFilters({ ...contractsFilters, page: pagination.current });
  };

  const getAllContracts = (setLoading, filters, dispatch, setTotal) => {
    const { page } = filters;

    setLoading(true);

    request.post('/partnersContracts/getPartnersContracts', { page }, ({ data, total }) => {
      dispatch(setContractsList(data));
      setTotal(total);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllContracts(setLoading, contractsFilters, dispatch, setTotal);
  }, [contractsFilters]);

  const columns = getColumns(mob);

  const sortedContracts = list?.map((i) => i);

  return (
    <div className="contracts_page default_page">
      <h2>
        {translation.contracts[lang]} <div style={{ marginTop: '0.1vw' }}></div>
      </h2>
      <Row style={{ justifyContent: 'center' }}>
        <Button type="primary" onClick={() => dispatch(setModal({ name: NEW_CONTRACT }))}>
          <PlusCircleOutlined /> {translation.addContract[lang]}
        </Button>
      </Row>
      <br />
      <Table
        dataSource={sortedContracts}
        rowKey={(item) => item._id}
        loading={loading}
        columns={columns}
        e
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: -1,
          expandedRowRender: (item, i, indent, expended) => expended && <ContractInfo data={item} />,
        }}
        pagination={{ total, current: contractsFilters.page }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Contracts;
